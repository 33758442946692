import { Link } from 'react-router-dom';
import { scrollToHash } from '../../../../utils/hashHook';
import { OldDomstol, useTranslation } from 'app/hooks/useTranslations';

export const SkipToContent = (props: { language: string }) => {
    const { t } = useTranslation();

    return (
        <div id="skip-link">
            <Link tabIndex={0} to="#main-content" onClick={() => scrollToHash('main-content')}>
                {t(`${OldDomstol}.SkipToContent`, props.language)}
            </Link>
        </div>
    );
};

export default SkipToContent;
