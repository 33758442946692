import { OldDomstol, useTranslation } from 'app/hooks/useTranslations';
import { OldDomainPageType } from '../../../../Models/Content/OldDomainPageData';
import { Taxonomy, useEpiserver } from '@episerver/spa-core';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export const SiteTop = (props: { domainPage: OldDomainPageType; currentPage: Taxonomy.IContent }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const ctx = useEpiserver();
    const searchFieldRef = useRef();

    const [searchPageUrl, setSearchPageUrl] = useState<string>('#');
    const [queryString, setQueryString] = useState<string>('');

    const handleButtonPress = (e: React.MouseEvent<HTMLInputElement>) => {
        setQueryString('');
        e.preventDefault();
        const searchUrl = getSearchUrl();
        if (searchUrl) {
            redirectToSearchPage(searchUrl);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key == 'Enter') {
            setQueryString('');
            e.preventDefault();
            const searchUrl = getSearchUrl();
            if (searchUrl) {
                redirectToSearchPage(searchUrl);
            }
        }
    };

    function redirectToSearchPage(newSearchUrl: string) {
        const currentPath = location.pathname.split('?');
        const searchPage = ctx.getSpaRoute(newSearchUrl).split('?');
        if (currentPath.length > 0 && searchPage.length > 0 && currentPath[0] == searchPage[0]) {
            navigate(ctx.getSpaRoute(newSearchUrl));
            window.location.reload();
        } else {
            navigate(ctx.getSpaRoute(newSearchUrl));
        }
    }

    const getSearchUrl = () => {
        if (
            searchFieldRef &&
            searchFieldRef.current &&
            searchFieldRef.current['value'] &&
            searchPageUrl != null &&
            searchPageUrl != '#'
        ) {
            let searchUrl = new URL(searchPageUrl).pathname;
            searchUrl += '?search=' + searchFieldRef.current['value'];
            return searchUrl;
        }
        return new URL(searchPageUrl).pathname + '?search=';
    };

    useEffect(() => {
        setSearchPageUrl(props.domainPage?.searchPage?.value?.url ? props.domainPage?.searchPage?.value?.url : '#');
    });

    function toggleDropDownMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        const toggleElement = document.getElementById('main-menu');

        if (toggleElement.classList.contains('is-active')) {
            toggleElement.classList.remove('is-active');
            e.currentTarget.setAttribute('aria-expanded', 'false');
        } else {
            toggleElement.classList.add('is-active');
            e.currentTarget.setAttribute('aria-expanded', 'true');
        }
    }

    return (
        <div
            className="page-header__top"
            style={
                props.domainPage?.contentType?.includes('OldDomainPage') &&
                !(props.domainPage as any)?.displayBackgroundImage?.value
                    ? { backgroundImage: 'none' }
                    : {}
            }
        >
            {props.currentPage.contentType.includes('OldDomainPage') && (
                <h1 className="sr-only">{(props.currentPage as any)?.heading?.value}</h1>
            )}
            {props.domainPage?.contentType?.includes('OldDomainPage') &&
            (props.domainPage as any)?.screen?.logo?.value &&
            !!(props.domainPage as any)?.screen?.nameProperty?.value ? (
                <>
                    <div className="page-header__top-logo-only hide-for-print">
                        <div className="page-header__item">
                            <img src={(props.domainPage as any)?.screen?.logo?.value?.url} alt={''} />
                        </div>
                        {/* TODO: allow different links */}
                        <Link className="page-header__item" to={props.domainPage?.contentLink.url}>
                            <img
                                src={(props.domainPage as any)?.screen?.nameProperty?.value?.url}
                                alt={props.domainPage?.name as string}
                            />
                        </Link>
                    </div>
                    <div className="page-header__top-logo-only show-for-print">
                        <img
                            src={(props.domainPage as any)?.print?.logo?.value?.url}
                            alt={props.domainPage?.name as string}
                        />
                        <img
                            src={(props.domainPage as any)?.print?.nameProperty?.value?.url}
                            alt={props.domainPage?.name as string}
                        />
                    </div>
                </>
            ) : (
                <>
                    {/* TODO: finish */}
                    <div className="page-header__top-logo">
                        {props.domainPage?.contentType?.includes('OldDomainPage') &&
                        (props.domainPage as any)?.screen?.logo?.value ? (
                            <img
                                src={
                                    (props.domainPage as any)?.screen?.logo?.value?.url +
                                    '?width=100&height=180&Scale=Canvas&Mode=Crop&quality=85'
                                }
                                alt={''}
                            />
                        ) : (
                            <img
                                src="/StaticContent/UI/icons/riksvapen_min.svg"
                                width="50"
                                height="90"
                                alt="Riksvåpen"
                                className="logo"
                            />
                        )}
                    </div>
                    <div className="page-header__top-title">
                        <h1>
                            <Link to={props.domainPage?.contentLink?.url}>
                                {props.domainPage?.heading?.value
                                    ? props.domainPage?.heading?.value
                                    : props.domainPage?.name?.toString()}
                            </Link>
                        </h1>
                    </div>
                </>
            )}

            {((props.domainPage?.contentType?.includes('OldDomainPage') &&
                (props.domainPage as any)?.displaySearch?.value) ||
                !props.domainPage?.contentType?.includes('OldDomainPage')) && (
                <div className="page-header__top-search search">
                    {/* TODO: for/id/map search to new search */}
                    <form role="search" aria-label={t(`${OldDomstol}.Search`, props.currentPage.language.name)}>
                        <label htmlFor="global-search">
                            {t(`${OldDomstol}.Searchlabel`, props.currentPage.language.name)}
                        </label>
                        <input
                            onKeyPress={handleKeyPress}
                            ref={searchFieldRef}
                            type="text"
                            id="global-search"
                            name="search"
                            value={queryString}
                            onChange={(e) => {
                                setQueryString(e.currentTarget.value.toString());
                            }}
                        />
                        <input
                            onClick={handleButtonPress}
                            type="submit"
                            className="button--search"
                            value={t(`${OldDomstol}.QuickSearch`, props.currentPage.language.name)}
                        />
                    </form>
                </div>
            )}
            <button
                onClick={(e) => toggleDropDownMenu(e)}
                aria-controls="main-menu"
                aria-expanded="false"
                className="main-menu__toggler reset-button"
                data-togglemenu
            >
                <span>{t(`${OldDomstol}.Menu`, props.currentPage.language.name)}</span>
                <img src="/StaticContent/UI/icons/menu.svg" alt="" />
            </button>
        </div>
    );
};

export default SiteTop;
